import { t } from "i18next";
import { useState, useRef, MutableRefObject, useEffect } from "react";
import styles from "./CrmGridProcessSelectedRowsButton.module.scss";
import { ReactComponent as ProcessIcon } from '@assets/Icons/table-icon.svg';
import { ReactComponent as CloseBtnSvg } from '@assets/Icons/close-button-icon.svg';
import { Overlay } from "react-overlays";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";
import { useAppSelector } from "@core/Redux/hooks";
import { selectPluginsState } from "@core/Redux/store";
import { IMultiEditor } from "@pluginShared/i-plugin-contract";
import { loadedMultiEditors } from "@core/Plugins/pluginManager";
import { BulkUpdateApi, BulkUpdateStatus, IBulkUpdateResult } from "@core/EventSourcing/BulkUpdateApi";
import { Result, Spin } from "antd";

export interface ICrmGridProcessSelectedRowsButtonProps {
    bulkUpdateApi: BulkUpdateApi;
    tableId: string;
}

export function CrmGridProcessSelectedRowsButton(props: ICrmGridProcessSelectedRowsButtonProps) {
    const [showProcessPluginsList, setShowProcessPluginsList] = useState<boolean>(false);
    const processPluginsListButtonRef = useRef<any>();

    const HandleShowProcessPluginsList = () => {
        setShowProcessPluginsList(prev => !prev);
    };

    return <>
        {props.bulkUpdateApi.length > 0 && <>
            <div ref={processPluginsListButtonRef} onClick={HandleShowProcessPluginsList} className={styles.button}>
                <ProcessIcon/>
                <span>{t("process-selected")}</span>
            </div>
            {showProcessPluginsList &&
                <CrmGridProcessSelectedRowsPanel
                    onHide={() => setShowProcessPluginsList(false)}
                    targetRef={processPluginsListButtonRef}
                    bulkUpdateApi={props.bulkUpdateApi}
                    tableId={props.tableId}
                />
            }
        </>}
    </>;
}

interface ICrmGridProcessSelectedRowsPanelProps {
    onHide: () => void;
    targetRef: MutableRefObject<any>;
    bulkUpdateApi: BulkUpdateApi;
    tableId: string;
}

function CrmGridProcessSelectedRowsPanel(props: ICrmGridProcessSelectedRowsPanelProps) {
    const [selectedPlugin, setSelectedPlugin] = useState<IMultiEditor | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [result, setResult] = useState<IBulkUpdateResult | null>(null);

    const pluginsState = useAppSelector(selectPluginsState);

    const multiEditors: IMultiEditor[] = pluginsState.modules.flatMap(module => 
        module.installation.multiEditors.map(x => loadedMultiEditors[x.id ?? ""])
    )

    const onStartUpdate = () => {
        setIsLoading(true);
    }

    const onAfterUpdate = (result: IBulkUpdateResult) => {
        setIsLoading(false);
        setResult(result);
    }

    useEffect(() => {
        props.bulkUpdateApi.handleOnStartUpdate(onStartUpdate);
        props.bulkUpdateApi.handleOnAfterUpdate(onAfterUpdate);
    }, [onStartUpdate, onAfterUpdate]);

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Escape":
                onHide();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onHide = () => {
        if (!isLoading) {
            props.onHide();
        }
    }

    return (<Overlay
        show={true}
        flip={true}
        placement={'bottom-start'}
        rootClose={true}
        onHide={onHide}
        target={props.targetRef}
        popperConfig={popperConfig}
    >
        {({ show, props: overlayProps, arrowProps, placement }) => 
            <div {...overlayProps} className={styles.host}>
                <div className={styles.component}>
                    {result == null
                        ? <Spin spinning={isLoading}>
                            <span>{t("selected-count")}: {props.bulkUpdateApi.length}</span>
                            <br/><br/>
                            {selectedPlugin == null
                                ? <div>
                                    <h1>{t("select-plugin")}:</h1>
                                    <ul>{multiEditors.map(plugin => <li key={plugin.id}>
                                        <br/>
                                        <a onClick={() => setSelectedPlugin(plugin)}>{plugin.caption}</a>
                                    </li>)}</ul>
                                </div>
                                : <div>
                                    <a onClick={() => setSelectedPlugin(null)}>{t("back")}</a>
                                    <h1>{selectedPlugin.caption}</h1>
                                    <selectedPlugin.func selectedEntities={props.bulkUpdateApi} tableId={props.tableId}/>
                                </div>
                            }
                        </Spin>
                        : <Result
                            status={result.status == BulkUpdateStatus.Done ? "success" : "error"}
                            title={result.status == BulkUpdateStatus.Done ? t("success") : t("error")}
                            subTitle={`${t("completed-rows")}: ${result.numberOfCompleted}`}
                            extra={[
                                <PrimaryButton key="0" onClick={onHide}>{t("close")}</PrimaryButton>
                            ]}
                        />
                    }
                    <button type="button"
                            className={styles.closeButton}
                            onClick={onHide}>
                        <CloseBtnSvg />
                    </button>
                </div>
            </div>
        }
    </Overlay>)
}

const popperConfig = {
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                boundary: 'viewport',
            },
        },
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['left', 'right', 'bottom'],
            },
        },
    ]
};
