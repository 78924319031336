import { IEntity, IEntityData } from "@core/Models/i-entity";
import { IEventSourcingStoreReactClient } from "./EventSourcingClientBase";

export enum BulkUpdateStatus {
    Done = "Done",
    Error = "Error",
}

export interface IBulkUpdateResult {
    status: BulkUpdateStatus;
    errorMessage?: string;
    numberOfCompleted: number;
}

export class BulkUpdateApi {
    private store: IEventSourcingStoreReactClient;
    private filterQuery: any;
    private queryVars: Map<string, any>;
    private selectedEntityIds: string[] | "all";
    private selectedCount: number;

    private onStartUpdateHandlers: (() => void)[] = [];
    private onAfterUpdateHandlers: ((result: IBulkUpdateResult) => void)[] = [];

    constructor(store: IEventSourcingStoreReactClient, filterQuery: any, queryVars: Map<string, any>, selectedEntityIds: string[] | "all", allCount: number) {
        this.store = store;
        this.filterQuery = filterQuery;
        this.queryVars = queryVars;
        this.selectedEntityIds = selectedEntityIds;

        if (selectedEntityIds == "all") {
            this.selectedCount = allCount;
        }
        else {
            this.selectedCount = selectedEntityIds.length;
        }
    }

    public async getSelectedEntityIds(): Promise<string[]> {
        return this.selectedEntityIds == "all"
            ? await this.store.queryIds(this.filterQuery, this.queryVars)
            : this.selectedEntityIds;
    }

    public async update(callback: (entity: IEntity) => IEntityData): Promise<IBulkUpdateResult> {
        this.onStartUpdate();

        const selectedEntityIds = await this.getSelectedEntityIds();
        const result = await this.store.multiUpdate(selectedEntityIds, callback);

        this.onAfterUpdate(result);

        return result;
    }

    public getCount(): number {
        return this.selectedCount;
    }

    public get length(): number {
        return this.selectedCount;
    }

    public handleOnStartUpdate(handler: () => void) {
        this.onStartUpdateHandlers.push(handler);
    }

    private onStartUpdate() {
        this.onStartUpdateHandlers.forEach(handler => {
            handler();
        })
    }

    public handleOnAfterUpdate(handler: (result: IBulkUpdateResult) => void) {
        this.onAfterUpdateHandlers.push(handler);
    }

    private onAfterUpdate(result: IBulkUpdateResult) {
        this.onAfterUpdateHandlers.forEach(handler => {
            handler(result);
        })
    }
}