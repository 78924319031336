import {EntitiesView} from './OrdersView/OrdersView';
import {PageHeader} from '@core/VisualComponents/Page/PageHeader/PageHeader';
import {Page} from '@core/VisualComponents/Page/Page';
import {PageHeaderRow} from '@core/VisualComponents/Page/PageHeader/PageHeaderRow/PageHeaderRow';
import {PrimaryButton} from '@core/VisualComponents/Buttons/PrimaryButton';
import {ReactComponent as AddButtonSvg} from '@assets/Icons/add-button-icon.svg';
import {PageTitleContainer} from '@core/VisualComponents/Page/PageHeader/PageTitleContainer/PageTitleContainer';
import {PageHeaderAside} from '@core/VisualComponents/Page/PageHeader/PageHeaderAside/PageHeaderAside';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useLocalStorage} from '@core/Hooks/use-local-storage';
import ReactDOM from 'react-dom';
import {useAppDispatch, useAppSelector, useFiltersView} from '@core/Redux/hooks';
import {PageHeaderRowEnganced} from '@core/VisualComponents/Page/PageHeader/PageHeaderRowEnganced/PageHeaderRowEnganced';
import {OrderFilters} from './OrderFilters/OrderFilters';
import {DeviceTypeContext} from '@core/Contexts/DeviceTypeContext';
import styles from './ListOrdersPage.module.scss';
import {BeforeMobileMainMenuRefContext} from '@core/Contexts/BeforeMobileMainMenuRefContext';
import {useNavigate} from 'react-router-dom';
import {entityCreatePath, entityEditPath} from '@core/Constants/route-paths';
import { selectIsNoEntities
    , selectEntitiesIds
    , selectQueryStatus
    , updateEntitySet
    , updateIndividualEntities
    , updateStatus,
    selectEntitiesCount
} from '@core/Redux/Slices/ordersSlice/storesSlice';
import { ReactComponent as FunnelXmarkIcon } from "@assets/Icons/funnel-xmark-icon.svg";

import {useTranslation} from "react-i18next";
import { selectAppErrorsState, selectPluginsState, selectSortDirection, selectSortField, selectTableConfig, selectTableName, selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import {IFilter} from './OrderFilters/OrderFilters'
import {ViewMode, ViewSelector} from './Components/ViewSelector'
import { StoreContext } from '@core/Stores/EventSourcingStoreProvider';
import _ from 'lodash';
import { setFilterOffset } from '@core/Redux/Slices/filtersViewSlice';
import { GreetingPanel } from './Components/GreetingPanel';
import Logger from 'js-logger';
import { StatusIndicator } from './Components/StatusIndicator';
import { SearchBar } from './Components/SearchBar';
import { deleteAppError, openTable } from '@core/Redux/Slices/appSlice';
import { TableSelector } from './Components/TableSelector';
import { getMergedMongoQueries } from './OrderFilters/OrderFiltersMisc';
import { CreateOrEditEntityModal } from './CreateOrEditEntity/CreateOrEditEntityModal';
import { createQueryVars } from '@core/Stores/QueryVars';
import { CrmSortDirection, ICrmField } from '@core/Models/autogenerated/tenantConfig.models';
import { CrmGridAddColumnButton } from '@core/VisualComponents/CrmGridView/CrmGridAddColumnButton/CrmGridAddColumnButton';
import { CrmGridProcessSelectedRowsButton } from '@core/VisualComponents/CrmGridView/CrmGridProcessSelectedRowsButton/CrmGridProcessSelectedRowsButton';
import { BulkUpdateApi } from '@core/EventSourcing/BulkUpdateApi';

const ListItemsPerPage = 100;
const GridItemsPerPage = 100;

export function ListOrdersPage(props: {tableId: string}) {
    const { t } = useTranslation();

    const navigateTo = useNavigate();
    const beforeMobileMainMenuRef = useContext(BeforeMobileMainMenuRefContext);
    const deviceType = useContext(DeviceTypeContext);
    const dispatch = useAppDispatch();
    const filteredOrderIds = useAppSelector(selectEntitiesIds(props.tableId));
    const isNoEntities = useAppSelector(selectIsNoEntities(props.tableId));
    const entitiesCount = useAppSelector(selectEntitiesCount(props.tableId));

    const [filterError, setFilterError] = useState<string|null>(null);
    const [searchQuery, setSearchQuery] = useState<string|null>(null);
    const [filterQuery, setFilterQuery] = useState<any|null>();
    const [filterFieldIds, setFilterFieldIds] = useState<string[]|undefined>(undefined);
    const [simpleQueries, setSimpleQueries] = useState<{[columnId: string]: any}>({});
    const currentFilter = useRef<IFilter|null>(null);
    const [editingEntityId, setEditingEntityId] = useState<string | null>(null);
    const [selectedEntityIds, setSelectedEntityIds] = useState<string[] | "all">([]);
    
    const stores = useContext(StoreContext);
    const defaultSortDirection = useAppSelector(selectSortDirection(props.tableId));
    const defaultSortField = useAppSelector(selectSortField(props.tableId));
    const tenantConfig = useAppSelector(selectTenantConfig);
    const tableConfig = useAppSelector(selectTableConfig(props.tableId));
    const tableName = useAppSelector(selectTableName(props.tableId));
    const userInfo = useAppSelector(selectUserInfo);
    
    const pluginsState = useAppSelector(selectPluginsState);

    const showCheckboxes = userInfo?.role == "SuperUser" && pluginsState.modules.some(module => module.installation.multiEditors.length > 0);

    const [sortDirection, setSortDirection] = useState(defaultSortDirection);

    const [viewMode, storeViewMode] = useLocalStorage<ViewMode>('ordersViewMode', {
        initialValue: deviceType.isMobile ? ViewMode.List : ViewMode.Grid
    });

    const filterFields = useMemo(() => {
            if (filterFieldIds) {
                return filterFieldIds.map(fieldId => tableConfig?.fields.find(field => field.id == fieldId)).filter(field => field != null) as ICrmField[];
            }
            return tableConfig?.fields.filter(field => !field.hidden) ?? [];
        },
        [filterFieldIds, tableConfig?.fields]);

    let itemsPerPage : number;
    switch (viewMode) {
        case ViewMode.List:
            itemsPerPage = ListItemsPerPage;
            break;
        case ViewMode.Grid:
            itemsPerPage = GridItemsPerPage;
            break;
        default:
            itemsPerPage = GridItemsPerPage;
    }

    const queryStatus = useAppSelector(selectQueryStatus(props.tableId));
    const appErrors = useAppSelector(selectAppErrorsState);
    const filtersView = useFiltersView();

    const store = stores[props.tableId];

    useEffect(() => {
        if (tableConfig != null) {
            dispatch(openTable({table: tableConfig}));
        }
    }, [tableConfig]);

    const queryVars = useMemo( () => createQueryVars(userInfo), [userInfo]);

    store.useQuerySubscribe(filterQuery, queryVars
        , filtersView.currentFilterOffset
        , itemsPerPage, sortDirection === CrmSortDirection.Asc
        , (entities) => dispatch(updateEntitySet({tableId: props.tableId, entities: entities}))
        , (entities) => dispatch(updateIndividualEntities({tableId: props.tableId, entities: entities}))
        , (status) => { 
            dispatch(updateStatus({
                tableId: props.tableId,
                status: status
            }));
    });

    const bulkUpdateApi = useMemo(
        () => new BulkUpdateApi(store, filterQuery, queryVars, selectedEntityIds, queryStatus.resultCount),
        [store, filterQuery, queryVars, selectedEntityIds, queryStatus.resultCount]);

    const setViewMode = (mode : ViewMode) => {
        storeViewMode(mode);
    };

    const onCreateEntityClicked = useCallback(() => {
        if (deviceType.isMobile) {
            navigateTo(entityCreatePath(props.tableId));
        }
        else {
            setEditingEntityId("");
        }
    }, [navigateTo, deviceType]);

    const onEditEntityClicked = useCallback((entityId: string) => {
        if (deviceType.isMobile) {
            navigateTo(entityEditPath(props.tableId, entityId));
        }
        else {
            setEditingEntityId(entityId);
        }
    }, [navigateTo, deviceType]);


    const onSearch = useCallback((text: string) => {
        setSearchQuery(text);
    }, [filterQuery]);

    const onClearSearch = useCallback(() => {
        setSearchQuery(null);
    }, []);

    const onFilterChange = useCallback((filter: IFilter) => {
        try {
            let currFilterQuery = filter.getQuery(itemsPerPage, defaultSortField);

            const query = getMergedMongoQueries(currFilterQuery, searchQuery);

            if (query) {
                if (!_.isEqual(query, filterQuery)) {
                    setFilterQuery(query);
                    setSortDirection(filter.defaultSortDirection);
                }
            }

            // TODO: если мы храним простые фильтры внутри вида,
            // то, по идее, такого быть не должно

            // If simple filters contains some field we always show it
            // Even if it's not in filter fields
            let newFilterFieldIds = filter.fields == null ? undefined : [...filter.fields];
            if (newFilterFieldIds != null) {
                for (const fieldId in simpleQueries) {
                    if (!newFilterFieldIds.includes(fieldId)) {
                        newFilterFieldIds.push(fieldId);
                    }
                }
            }

            if (!_.isEqual(newFilterFieldIds, filterFieldIds)) {
                setFilterFieldIds(newFilterFieldIds);
            }

            setFilterError(null);
            currentFilter.current = filter;

            if (!_.isEqual(simpleQueries, filter.simpleQueries)) {
                setSimpleQueries(filter.simpleQueries);
            }
        }
        catch (err: any) {
            Logger.error("filter missconfiguration", JSON.stringify(filter), err);
            setFilterError("filter error");
        }
    }, [store, filterQuery, filterFieldIds, itemsPerPage, defaultSortField, searchQuery, simpleQueries]);

    const cursorAtStart = filtersView.currentFilterOffset <= 0;
    const cursorAtEnd = filtersView.currentFilterOffset + itemsPerPage >= queryStatus.resultCount;

    const MoveCursor = useCallback((offset: number) => {
        if (offset > 0 && cursorAtEnd)
            return;

        if (offset < 0 && cursorAtStart)
            return;

        let targetOffset = filtersView.currentFilterOffset + offset;
        if (targetOffset < 0)
            targetOffset = 0;
        setFilterOffset(targetOffset);
    }, [cursorAtStart, cursorAtEnd, filtersView.currentFilterOffset]);

    const ResetCursor = useCallback(() => {
        setFilterOffset(0);
    },[]);

    const onChangedSimpleQuery = (columnId: string, query: any) => {
        let newSimpleQuery = {
            ...simpleQueries,
            [columnId]: query,
        }

        setSimpleQueries(newSimpleQuery);
        currentFilter.current?.setSimpleQueries(newSimpleQuery);
    }

    const onResetSimpleQuery = (columnId: string) => {
        let newSimpleQuery = {...simpleQueries};
        delete newSimpleQuery[columnId];

        setSimpleQueries(newSimpleQuery);
        currentFilter.current?.setSimpleQueries(newSimpleQuery);
    }

    const onResetAllSimpleFilters = () => {
        setSimpleQueries({});
        currentFilter.current?.setSimpleQueries({});
    }

    useEffect(() => {
        setSelectedEntityIds([]);
    }, [filterQuery, simpleQueries, props.tableId]);

    useEffect(() => {
        if (viewMode == ViewMode.List) {
            onResetAllSimpleFilters();
        }
    }, [viewMode]);

    const ResetAllSimpleFiltersButton = (props: {disabled?: boolean}) => {
        return (
            <span onClick={onResetAllSimpleFilters} className={styles.ResetButtonContainer + " " + (props.disabled ? styles.disabled : "")}>
                <FunnelXmarkIcon className={styles.icon}/>
                <span className={styles.text}>{t("reset-filters")}</span>
            </span>
        )
    }

    const onChangeSelectedRows = (entityIds: string[] | "all") => {
        if (entityIds != "all" && queryStatus.status == "live" && entityIds.length >= queryStatus.resultCount) {
            entityIds = "all";
        }
        setSelectedEntityIds(entityIds);
    }

    return (<Page>
        <PageHeader>
            <PageHeaderRow>
                <StatusIndicator tableId={props.tableId}/>
                <PageTitleContainer>
                    <TableSelector currentTableId={props.tableId} />
                </PageTitleContainer>
                <PageHeaderAside>
                    <SearchBar onSearch={onSearch} onClearSearch={onClearSearch}/>
                </PageHeaderAside>
            </PageHeaderRow>
            <PageHeaderRowEnganced>
                <OrderFilters
                    tableId={props.tableId}
                    onFilterChange={onFilterChange}
                    onResetAllSimpleFilters={onResetAllSimpleFilters}
                    showResetButton={!_.isEmpty(simpleQueries)}
                />
                {deviceType.isMobile && <ViewSelector viewMode={viewMode} setViewMode={setViewMode} />}
            </PageHeaderRowEnganced>
        </PageHeader>
        { appErrors.length > 0 &&
            <div className={styles.errorPanel}>
                {appErrors.map(error => <div key={error.id}>
                    <span>{error.message}</span>
                    <i className="fas fa-times"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(deleteAppError(error.id));
                        }}
                    ></i><br/>
                </div>)}
            </div>
        }
        <div className={deviceType.isMobile ? styles.mainPanelMobile : styles.mainPanel}>
            {deviceType.isMobile ||
                <div className={styles.buttonsPanel}>
                    <PrimaryButton onClick={onCreateEntityClicked}>
                        <AddButtonSvg/>
                        <span>{t("create")}</span>
                    </PrimaryButton>
                    {userInfo?.role == "SuperUser" && <>
                        <CrmGridAddColumnButton/>
                        <CrmGridProcessSelectedRowsButton bulkUpdateApi={bulkUpdateApi} tableId={props.tableId}/>
                    </>}
                    {_.isEmpty(simpleQueries) || <ResetAllSimpleFiltersButton/>}
                    <div className={styles.wrapper}></div>
                    <div className={styles.counter}>
                        {bulkUpdateApi.length > 0 &&
                            <span>{t("selected-count")}: {bulkUpdateApi.length}</span>
                        }
                    </div>
                    <div className={styles.counter}>
                        {queryStatus.status == "live" &&
                            <span>{t("total-records")}: {queryStatus.resultCount} / {entitiesCount}</span>
                        }
                    </div>
                    <ViewSelector viewMode={viewMode} setViewMode={setViewMode} />
                </div>
            }
            {isNoEntities && queryStatus.status != "loading"
                ? <GreetingPanel tableId={props.tableId} onCreate={onCreateEntityClicked}/>
                : <>{filterError == null
                    ? <EntitiesView viewMode={viewMode}
                                fields={filterFields}
                                itemsPerPage={itemsPerPage}
                                tableId={props.tableId}
                                entityIds={filteredOrderIds}
                                onEditEntity={onEditEntityClicked}
                                ResetCursor={ResetCursor}
                                MoveCursor={MoveCursor}
                                cursorAtStart={cursorAtStart}
                                cursorAtEnd={cursorAtEnd}
                                queryStatus={queryStatus}
                                simpleFilters={simpleQueries}
                                onChangedSimpleQuery={onChangedSimpleQuery}
                                onResetSimpleQuery={onResetSimpleQuery}
                                selectedRows={selectedEntityIds}
                                onChangeSelectedRows={showCheckboxes ? onChangeSelectedRows : undefined}/>
                    : <span>{filterError}</span>
                }</>
            }
            {editingEntityId == null ||
                <CreateOrEditEntityModal
                    tableId={props.tableId}
                    entityId={editingEntityId == "" ? undefined : editingEntityId}
                    onHide={() => setEditingEntityId(null)}
                />
            }
        </div>
        {beforeMobileMainMenuRef && beforeMobileMainMenuRef.current && ReactDOM.createPortal(
            <div className={styles.mobileAddButtonPanel}>
                <div className={styles.mobileAddButton}
                     onClick={onCreateEntityClicked}>
                    <AddButtonSvg/>
                </div>
            </div>, beforeMobileMainMenuRef.current
        )}
    </Page>);
}
